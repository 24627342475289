import axios from "axios";
// import { Base64 } from "js-base64";
import { getParamUrlByKey } from "../../extra/utils";

const Header = {
  Authorization: `Bearer ${getParamUrlByKey("token")}`,
};
const core_api = "https://dev.globalcare.com.vn";

async function apiForm(method, url, data) {
  // var data_base64 = Base64.encode(JSON.stringify(data));
  try {
    const res = await axios({
      method: method,
      url: `${core_api}${url}`,
      data,
      headers: Header,
    });
    return res;
  } catch (err) {
    console.log(err.response.data?.message);
    return err;
  }
}

export default { apiForm };

import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Gsale from "../../views";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Gsale />,
  },
];

export default function AppRouter() {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.component />}
            ></Route>
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

import axios from "axios";
import api from ".";

export async function getNews() {
  return api.apiForm("GET", `/wp-json/wp/v2/posts?_embed&per_page=10&offset=0`);
}
export function apiGetFileByUrl(url) {
  return axios({
    method: "GET",
    url: url,
    responseType: "blob",
  });
}

import { saveAs } from "file-saver";
import { apiGetFileByUrl } from "../../controller/api/callApi";
import {
  list_product,
  list_story,
  salekits,
  support_info,
} from "../../extra/hard-data";

export default function gsaleReducer({ state, dispatch, action }) {
  const cases = {
    initial: () => {
      const { inforData, supportData } = state;
      inforData.selected = 1; //tab3
      supportData.info = support_info;
      // const dataApi = await getNews();
      return {
        products: list_product,
        stories: list_story,
        inforData,
        supportData,
      };
    },
    selectNav: () => ({ navSelected: action.id }),
    setOpenDrawer: () => {
      return { openDrawer: !state.openDrawer };
    },
    selectInfo: () => {
      const { inforData } = state;
      inforData.selected = action.id;
      return { inforData };
    },
    setExpanded: () => {
      const { supportData } = state;
      const { newExpanded, panel } = action;
      supportData.expanded = newExpanded ? panel : false;
      return { supportData };
    },

    learnMore: () => {
      const { inforData } = state;
      inforData.selected = action.id ? action.id : 0;
      inforData.more = true;
      return { navSelected: 3, inforData };
    },

    unMore: () => ({ inforData: { ...state.inforData, more: null } }),

    downloadFile: async () => {
      const { inforData } = state;
      const selected = inforData.selected;
      const salekit = salekits.find((i) => i.id === selected) || {};
      const linkPDF = salekit.link || "";
      if (linkPDF) {
        dispatch("onLoading");
        const dataFillFromUrl = await apiGetFileByUrl(linkPDF);
        if (selected === 1 || selected === 4) {
          saveAs(dataFillFromUrl?.data, `Salekit-${salekit?.name || ""}`);
        } else {
          saveAs(dataFillFromUrl?.data, `Salekit-${salekit?.name || ""}.pdf`);
        }
      }
      return { loading: false };
    },
    setTime: () => ({ date: action.date, time: action.time }),
    onLoading: () => ({ loading: true }),
  };

  return cases[action?.type];
}

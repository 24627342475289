import { Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect } from "react";
import useReducer from "../library/hooks/useReducer";
import { useStyles } from "../styles/homeStyles";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import GsaleZone from "./page/GsaleZone";
import Home from "./page/Home";
import Information from "./page/Infomation";
import Support from "./page/Support";
import gsaleReducer from "./reducers/gsaleReducer";

import OnTopIcon from "../assets/icons/on-top.svg";

const initialState = {
  openDrawer: false,
  products: [],
  stories: [],
  navSelected: 1,
  inforData: {},
  supportData: {},
  loading: false,
  date: "",
  time: "",
  addUser: 0,
};

export default function Gsale() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: gsaleReducer,
    name: "gsaleReducer",
    initialState,
  });

  const { products, navSelected, stories, date, time } = state;

  useEffect(() => {
    dispatch("initial");

    const updateDateTime = () => {
      const now = new Date();
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      const date = now.toLocaleDateString("en-US", options);

      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const time = `${hours}h${minutes}p`;
      dispatch({ type: "setTime", date, time });
    };

    updateDateTime();
    const interval = setInterval(updateDateTime, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderPage = (navSelected) => {
    switch (navSelected) {
      case 1:
        return (
          <Home
            products={products}
            stories={stories}
            isMobile={isMobile}
            date={date}
            time={time}
            classes={classes}
            dispatch={dispatch}
          />
        );
      case 2:
        return (
          <GsaleZone stories={stories} classes={classes} isMobile={isMobile} />
        );
      case 3:
        return (
          <Information dispatch={dispatch} isMobile={isMobile} state={state} />
        );
      case 4:
        return <Support dispatch={dispatch} state={state} />;

      default:
        break;
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "100%", maxWidth: { md: "1440px" } }} elevation={2}>
        <Navbar dispatch={dispatch} state={state} />
        {renderPage(navSelected)}
        <Footer dispatch={dispatch} />
        {!isMobile && (
          <div className={classes.chat}>
            <img
              src={OnTopIcon}
              alt="zalo"
              className="icon"
              onClick={scrollTop}
            />
          </div>
        )}
      </Paper>
    </div>
  );
}

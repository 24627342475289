import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Title from "./Title";

import Commas2 from "../../assets/icons/2-commas.svg";
import Star5Icon from "../../assets/icons/5-star.svg";
import RightIcon from "../../assets/icons/arrow-right.svg";
import PrevIcon from "../../assets/icons/prev.svg";

const Stories = ({ list }) => {
  const isMobile = useMediaQuery("(max-width:992px)");
  const slidesPerView = isMobile ? 1 : 3;
  const classes = useStyles();
  return (
    <div className={classes.story}>
      <div className="mx-30">
        <Title
          colorText="Những câu chuyện từ Top Sellers"
          text="trong hệ thống"
        />
      </div>
      <div className="relative">
        <div className="swiper-button image-swiper-button-prev">
          <img src={PrevIcon} alt="Prev" className="arrow-icon" />
        </div>
        <div className="swiper-button image-swiper-button-next">
          {/* <img src={NextIcon} alt="Next" className="arrow-icon" /> */}
          <div className="right-icon">
            <img src={RightIcon} alt="Next" style={{ width: 18 }} />
          </div>
        </div>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={25}
          loop={true}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          modules={[Navigation]}
          className="mySwiper mx-30"
        >
          {(list || []).map((i, index) => (
            <SwiperSlide key={index}>
              <div className="slide">
                <div className="row">
                  <img src={i.icon} alt="img-4" className="avt" />
                  <div className="info">
                    <div className="name">{i.name}</div>
                    <div>Top Seller</div>
                    <img src={Star5Icon} alt="img-4" />
                  </div>
                  <div>
                    <img src={Commas2} alt="img-4" className="comma" />
                  </div>
                </div>
                <div className="content">{i.comment}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default Stories;

const useStyles = makeStyles((theme) => ({
  story: {
    "& .mx-30": {
      margin: "0 8%",
      padding: "0 15px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
        padding: "0 25px",
      },
    },
    "& .slide": {
      boxShadow: "0px 12px 12px rgba(0, 0, 0, 0.1)",
      borderRadius: 20,
      padding: 30,
      // transition: "all 0.5s ease",
      minHeight: "255px",
      // "&:hover": {
      //   transform: "scale(1.03)",
      // },
      [theme.breakpoints.down("lg")]: {
        padding: 25,
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .info": {
      textAlign: "left",
      color: "#4B4B4B",
      fontSize: 14,
      lineHeight: 1.7,
      flexGrow: 0.5,
    },
    "& .name": {
      color: "#006838",
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.2,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    "& .arrow-icon": {
      [theme.breakpoints.down("sm")]: {
        width: "65px",
      },
    },
    "& .right-icon": {
      background: "linear-gradient(267.1deg, #006838 11.88%, #00A69C 106.66%)",
      boxShadow: "-4px 4px 20px rgba(32, 180, 134, 0.12)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 100,
      padding: 7,
      margin: "15px 20px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px 10px",
      },
    },
    "& .avt": {
      marginRight: "8px",
      width: "60px",
      height: "60px",
      borderRadius: 30,
      [theme.breakpoints.down("lg")]: {
        width: "50px",
        height: "50px",
      },
    },
    "& .comma": {
      width: "40px",
      [theme.breakpoints.down("lg")]: {
        width: "30px",
      },
    },
    "& .content": {
      marginTop: 15,
      textAlign: "justify",
      fontStyle: "italic",
    },
    "& .swiper-wrapper": { marginBottom: "30px" },
    "& .relative": { position: "relative" },
    "& .swiper-button": {
      position: "absolute",
      zIndex: 2,
      cursor: "pointer",
      marginTop: "110px",
    },
    "& .image-swiper-button-next": {
      right: "5%",
      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
    },
    "& .image-swiper-button-prev": {
      left: "5%",
      [theme.breakpoints.down("sm")]: {
        left: "-10px",
      },
    },
  },
}));

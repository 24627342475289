import moment from "moment";

export const getParamUrlByKey = (key) => {
  if (!key) return;
  const search = window.location.search;
  const tmp = search.split("?");
  const params = new URLSearchParams(tmp[1]);
  return params.get(key);
};

export const formatMoney = (money) => {
  return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "--";
};

// ** ANIMATION
export const animateRight = {
  initial: { x: 50, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -50, opacity: 0 },
  transition: { duration: 0.5 },
};
export const animateLeft = {
  initial: { x: -50, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: 50, opacity: 0 },
  transition: { duration: 0.5 },
};

export const animateChild = {
  initial: "hidden",
  whileInView: "visible",
  viewport: { amount: 0.5 },
  variants: {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  },
};

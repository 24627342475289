import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#EFF8F7",
    color: "#4B4B4B",
    padding: "30px 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
      padding: "30px 5%",
    },
    "& .logo": {
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "25%",
      },
    },
    "& .logo-gc": {
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "45%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "22%",
      },
    },
    "& .divider": {
      margin: "20px 0",
    },
    "& .row-icon": {
      display: "flex",
      flexDirection: "row",
    },
    "& .margin-left": {
      marginLeft: "8px",
    },
    "& .bold": {
      fontWeight: 700,
      marginBottom: "16px",
    },
    "& .text": {
      lineHeight: 1.8,
      color: "#4B4B4B",
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& .normal-text": {
      lineHeight: 1.8,
      color: "#4B4B4B",
    },
    "& .img": {
      marginLeft: "-8px",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      marginTop: "5px",
    },
    "& .icon": {
      margin: "15px 20px 15px 0",
      "&:hover": {
        cursor: "pointer",
        borderRadius: "50%",
        boxShadow: "0px 2px 10px -2px #006838",
      },
    },
    "& .down-img": {
      marginBottom: "15px",
      "&:hover": {
        boxShadow: "0px 2px 10px -2px #006838",
      },
    },
  },
  right: {
    background: "#4B4B4B",
    color: "#FFF",
    textAlign: "center",
    padding: "14px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
    textAlign: "center",
    transition: "all .2s ease-in",
    "& .banner": {
      width: "calc(100% - 60px)",
      cursor: "pointer",
      borderRadius: "0 0 35px 35px",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 20px)",
        borderRadius: 0,
        margin: 0,
      },
    },
    "& .full-width": {
      width: "100%",
    },
    "& .image-1": {
      width: "100%",
      background:
        "linear-gradient(180deg, rgba(235, 248, 247, 0) 0%, #EFF8F7 100%)",
    },
    "& .my-40": { margin: "40px 0" },
    "& .mt-40": { marginTop: "40px" },
    "& .title-3": { marginTop: "40px", display: "flex", textAlign: "left" },
    "& .ml-8": { marginLeft: "8px" },
    "& .mr-15": { marginRight: "15px" },
    "& .mx-10": {
      [theme.breakpoints.down("sm")]: {
        margin: "0 10px",
      },
    },
    "& .p-8": {
      padding: "0 8%",
      marginTop: 30,
      [theme.breakpoints.down("sm")]: {
        padding: "0 10px",
        marginTop: 10,
      },
    },
    "& .download": {
      position: "absolute",
      left: 80,
      // bottom: 100,
      backfaceVisibility: "hidden",
      transform: "translateY(-20px)",
      cursor: "pointer",
      color: "red",

      "&:before": {
        content: "",
        position: "absolute",
        left: "-2px",
        top: "-3px",
        width: "calc(100% + 4px)",
        height: "calc(100% + 6px)",
        background: "rgba(255,255,255,0.4)",
        transform: "scaleX(0.3)",
        transformOrigin: "0 50%",
        transition: "transform 2s",
      },
      "&:hover:before": {
        transform: "scaleX(1)",
      },
    },
    "& .swiper-pagination-bullet-active": {
      backgroundColor: "#006838",
    },
  },

  partner: {
    marginTop: 30,
    padding: "15px 7%",
    background: "#EFF8F7",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },

    "& .swiper-wrapper": {
      height: "135px",
    },
    "& .swiper-pagination-bullet-active": {
      backgroundColor: "#006838",
    },
  },
  block: {
    display: "flex",
    alignItems: "center",
    padding: "0 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
    "& .my-20": {
      [theme.breakpoints.down("sm")]: {
        margin: "20px 0",
      },
    },
    "& .summary": {
      color: "#4B4B4B",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "15px !important",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "15px 0",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        margin: 0,
      },
    },
    "& .row-number": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "15px 0",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "38px",
      color: "#006838",
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
        lineHeight: 1,
        margin: "15px 0",
      },
      [theme.breakpoints.between("sm", "md")]: {
        margin: 0,
        fontSize: "30px",
      },
    },
    "& .left": {
      textAlign: "left",
      lineHeight: 1.5,
    },
    "& .w-80": {
      width: "80%",
    },
    "& .image-2": {
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .title": {
      color: "#1A1A1A",
      fontSize: "35px",
      fontWeight: 700,
      textAlign: "left",
      margin: "20px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    "& .color": { color: "#006838" },
    "& .divider": {
      backgroundColor: "#61646D",
      height: "3px",
      width: "100px",
      marginTop: "15px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "35%",
      },
    },
    "& .row-item": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "20px 0",
    },
    "& .image-5": {
      width: "70%",
      marginTop: "40px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .bg-white": {
      padding: 15,
      margin: 0,
      "&:hover": {
        borderRadius: 3,
        backgroundColor: "#fff",
        boxShadow: "0px 22px 25px rgba(0, 0, 0, 0.1)",
      },
    },
    "& .icon": {
      marginRight: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
      },
    },
  },
  bgGradient: {
    marginTop: 50,
    background:
      "linear-gradient(180deg, rgba(235, 248, 247, 0) 0%, #EFF8F7 100%)",
    "& .text": {
      textAlign: "left",
      color: "#4B4B4B",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .bold": {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: 2,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  bgColor: {
    backgroundColor: "#EFF8F7",
  },

  rowImage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "40px 0",
    "& .step": {
      maxWidth: "30%",
      padding: "0 20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .img": {
      paddingTop: 30,
      transition: "transform 0.3s ease",
      transform: "translateY(0)",
      "&:hover": {
        transform: "translateY(-5px)",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    "& .text": {
      textAlign: "left",
      color: "#4B4B4B",
      lineHeight: 1.4,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .bold": {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: 2,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  stepper: {
    position: "relative",
    "& .mx-30": {
      margin: "0 8%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .arrow-icon": {
      width: "65px",
    },
    "& .right-icon": {
      background: "linear-gradient(267.1deg, #006838 11.88%, #00A69C 106.66%)",
      boxShadow: "-4px 4px 20px rgba(32, 180, 134, 0.12)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 100,
      padding: 6,
      margin: "-5px 10px 0",
    },
    "& .text": {
      textAlign: "left",
      color: "#4B4B4B",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .bold": {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: 2,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .img": {
      paddingTop: 20,
    },
  },
  timeBox: {
    bottom: 0,
    position: "absolute",
    background: "#fff",
    boxShadow: "0px 17.0124px 19.4427px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
    borderRadius: 12,
    padding: 10,
    "& .label": {
      color: "#006838",
      fontWeight: "bold",
      fontSize: 17,
    },
    "& .row-line": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "5px 0",
    },
    "& .bg-color": {
      backgroundColor: "#7FD9D3",
      marginLeft: 10,
      padding: "5px 15px",
      borderRadius: 5,
    },
    "& .bold": {
      fontWeight: "bold",
      fontSize: 20,
    },
    "& .img": {
      height: 30,
    },
    "& .icon-5": {
      marginRight: 8,
    },
  },
  spacing: {
    height: 30,
  },
  chat: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "auto",
    bottom: "155px",
    right: "35px",
    overflow: "visible",
    zIndex: 999,
    [theme.breakpoints.down("md")]: {
      right: "30px",
    },

    "& .icon": {
      width: 45,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  container: {
    color: "#4B4B4B",
    lineHeight: 1.5,
    margin: "60px 23%",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 10px 40px",
      fontSize: 15,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .title": {
      fontSize: 32,
      fontWeight: 700,
      textAlign: "center",
      margin: "30px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    "& .way": {
      fontSize: 24,
      fontWeight: 700,
      margin: "15px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 21,
        margin: "10px 0 5px",
      },
    },
    "& .half": {
      width: "60%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },
    "& .mt-30": {
      marginTop: "30px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    "& .icon": {
      [theme.breakpoints.down("sm")]: {
        width: "26%",
      },
    },
  },
}));

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    "& .img": {
      width: "100%",
      borderRadius: "0 0 35px 35px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    "& .row": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    "& .mx-8": {
      margin: "0 8%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 10px",
      },
    },
    "& .mx-30": {
      margin: "0 30px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 10px",
      },
    },
    "& .ml-3": {
      marginLeft: "30px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    "& .ml-25": {
      marginLeft: "25px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    "& .bold": {
      fontWeight: 500,
    },
    "& .title": {
      fontSize: 36,
      textAlign: "left",
      margin: "30px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        fontWeight: 700,
        margin: "20px 0",
      },
    },
    "& .download": {
      fontSize: 24,
      textAlign: "center",
      fontWeight: 500,
      margin: "50px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "40px 10px",
      },
    },
    "& .icon": {
      marginLeft: 10,
      [theme.breakpoints.down("sm")]: {
        width: "10%",
      },
    },
    "& .MuiButtonBase-root": {
      fontWeight: 600,
      fontSize: 16,
      background: "linear-gradient(180deg, #03BC71 0%, #036C4D 100%)",
      borderRadius: "6px",
      boxShadow: " 0px 12px 16px rgba(0, 0, 0, 0.1)",
      height: 50,
      padding: "12px 20px",
      textTransform: "initial !important",
      transition: "all .5s ease",
      "&:hover": {
        transform: "scale(1.05)",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
      },
    },
    "& .menu-item": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #DCDCDC",
      background: "#F8F8F8",
      padding: "12px",
      fontWeight: 700,
      color: "#4B4B4B",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .color": {
      color: "#006838",
    },
    "& .full-width": {
      width: "100%",
      marginBottom: "20px",
    },
    "& .width-30": {
      width: "30%",
      marginRight: "12px",
    },
    "& .width-70": {
      width: "70%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .select": {
      marginBottom: "20px",
      borderRadius: "8px",
      background: "#F8F8F8",
      color: "#006838",
      fontWeight: 600,
    },
    // "&:focus": {
    //   border: "solid 1px #DCDCDC",
    // },
    // "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused": {
    //   border: "solid 1px #DCDCDC",
    // },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   border: "solid 1px #DCDCDC",
    // },
    "& .content": {
      lineHeight: 1.8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
    "& .text-bold": {
      fontWeight: 600,
      marginTop: 10,
    },
    "& .table": {
      overflow: "auto",
    },
    "& .row-table": {
      display: "flex",
      flexDirection: "row",
    },
    "& .border": {
      border: "0.5px solid #333",
      padding: "5px 10px",
    },
    "& .child-bor": {
      border: "0.5px solid #333",
      textAlign: "center",
    },
    "& .text-center": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .column": {
      flexDirection: "column",
    },
    "& .cell-2": {
      width: "20%",
    },
    "& .cell-4": {
      width: "40%",
    },
    "& .cell-6": {
      width: "60%",
    },
    "& .cell-8": {
      width: "80%",
    },
    "& .cell-3": {
      width: "30%",
      padding: "5px 10px",
      textAlign: "center",
    },
    "& .cell-5": {
      width: "50%",
      textAlign: "center",
    },
    "& .cell-95-bm": {
      width: "95%",
      minWidth: "804px",
    },
    "& .cell-05-bm": {
      width: "5%",
      minWidth: "42px",
      textAlign: "center",
    },
    "& .cell-2-bm": {
      width: "20%",
      minWidth: "155px",
    },
    "& .cell-35-bm": {
      width: "35%",
      minWidth: "273px",
      textAlign: "left",
    },
    "& .cell-full": {
      width: "calc(100% - 22px)",
      minWidth: "760px",
    },
    "& .cell-05-tv": {
      width: "5%",
      minWidth: "15px",
      textAlign: "center",
    },
    "& .cell-1-tv": {
      width: "10%",
      minWidth: "65px",
      textAlign: "center",
    },
    "& .cell-15-tv": {
      width: "15%",
      minWidth: "100px",
      textAlign: "center",
    },
    "& .cell-25-tv": {
      width: "25%",
      minWidth: "150px",
    },
    "& .cell-55-tv": {
      width: "55%",
      minWidth: "346px",
    },
    "& .cell-6-tv": {
      width: "calc(60% + 24px)",
      minWidth: "383px",
    },
    "& .cell-65-tv": {
      width: "65%",
      minWidth: "400px",
    },
    "& .cell-7-tv": {
      width: "calc(70% + 23px)",
      minWidth: "437px",
    },
    "& .h-90": {
      height: "90px",
    },
    "& .h-50": {
      height: "calc(50% - 12px)",
    },
    "& .br-t": {
      borderTop: "0.5px solid #333",
    },
    "& .br-tl": {
      width: "50%",
      borderTop: "0.5px solid #333",
      borderLeft: "0.5px solid #333",
    },
    "& .col-2-dq": {
      width: "20%",
      minWidth: "100px",
      border: "0.5px solid #333",
      padding: "5px 10px",
    },
    "& .col-4-dq": {
      width: "calc(40% + 23.5px)",
      minWidth: "338px",
      border: "0.5px solid #333",
      padding: "5px 10px",
      textAlign: "center",
    },
    "& .cell-4-dq": {
      width: "calc(40% + 23.5px)",
      minWidth: "320px",
    },
    "& .cell-05-mr": {
      width: "5%",
      minWidth: "28px",
      textAlign: "center",
    },
    "& .col-15-mr": {
      width: "15%",
      minWidth: "114px",
      border: "0.5px solid #333",
      padding: "5px 10px",
      textAlign: "center",
    },
    "& .cell-35-mr": {
      width: "35%",
      minWidth: "292px",
      textAlign: "left",
    },
    "& .col-4-mr": {
      width: "40%",
      minWidth: "342px",
      border: "0.5px solid #333",
      padding: "5px 10px",
    },
    "& .col-6-mr": {
      width: "60%",
      minWidth: "522px",
      border: "0.5px solid #333",
      padding: "5px 10px",
    },
    "& .cell-4-tc": {
      width: "40%",
      minWidth: "140px",
    },
    "& .cell-6-tc": {
      width: "60%",
      minWidth: "250px",
    },
    "& .col-8-gc": {
      width: "80%",
      minWidth: "348px",
      border: "0.5px solid #333",
      padding: "5px 10px",
    },
    "& .col-2-gc": {
      width: "20%",
      minWidth: "125px",
      border: "0.5px solid #333",
      padding: "5px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
    },
    "& .underline": {
      textDecoration: "underline",
    },
    "& .cell-center": {
      border: "0.5px solid #333",
      textAlign: "center",
      padding: "5px 10px",
    },
    "& .blue": {
      color: "blue",
      fontWeight: 600,
    },
    "& .bg-color": {
      backgroundColor: "#F0F7FF",
    },
    "& .bg-yellow": {
      backgroundColor: "#FFFACD",
    },
    "& .italic": {
      color: "#4169E1",
      fontStyle: "italic",
    },
    "& .bg-green": {
      backgroundColor: "#03AF6B",
    },
    "& .bg-lgreen": {
      backgroundColor: "#DDEDE2",
    },
    "& .b-600": {
      fontWeight: 600,
    },
    "& .p-0": {
      padding: 0,
    },
  },
}));

import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useStyles } from "../../styles/navbarStyles";

import DownloadIcon from "../../assets/icons/download.svg";
import GC from "../../assets/icons/gc.svg";
import Gsale from "../../assets/icons/gsale-1.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import SearchIcon from "../../assets/icons/search.svg";

//change Cẩm nang <=> GSale Zone
const navItems = [
  { id: 1, name: "Trang chủ" },
  { id: 2, name: "Cẩm nang" },
  { id: 3, name: "Sản phẩm" },
  { id: 4, name: "Hỗ trợ" },
];

export default function Navbar(props) {
  const { state, dispatch } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { openDrawer, navSelected } = state;

  const classes = useStyles();
  // setOpenDrawer((prevState) => !prevState)

  const drawer = (navSelected, dispatch) => (
    <Box onClick={() => dispatch("setOpenDrawer")} className="row">
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              className={`item ${
                navSelected === item.id ? "active" : "inActive"
              }`}
              onClick={() => dispatch({ type: "selectNav", id: item.id })}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className={classes.container}>
      <AppBar component="nav">
        <Toolbar>
          <a href="https://globalcare.com.vn/" target="_bank" className="img">
            <img src={GC} alt="gc img" />
          </a>
          <div className={classes.line}></div>
          <a href="https://gsale.com.vn/" target="_bank" className="img">
            <img src={Gsale} alt="gsale" />
          </a>
          <div className={classes.grow}></div>
          <div className={classes.search}>
            <InputBase
              fullWidth
              placeholder="Tìm kiếm"
              inputProps={{ "aria-label": "search" }}
              endAdornment={<img src={SearchIcon} alt="search" />}
            />
          </div>
          <div className={classes.grow}></div>
          <div className={classes.menu}>
            {navItems.map((item) => (
              <div
                key={item.id}
                className={`item ${
                  navSelected === item.id ? "active" : "inActive"
                }`}
                onClick={() => dispatch({ type: "selectNav", id: item.id })}
              >
                {item.name}
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            endIcon={<img src={DownloadIcon} alt="download" />}
            className={classes.button}
            href="https://core.globalcare.vn/app/gsale/download"
            target="_blank"
          >
            TẢI GSALE NGAY!
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => dispatch("setOpenDrawer")}
            sx={{ display: { lg: "none" }, marginLeft: "20px" }}
          >
            <img src={MenuIcon} alt="menu" style={{ width: "24px" }} />
          </IconButton>
          <Box component="nav">
            <Drawer
              anchor="right"
              variant="temporary"
              open={openDrawer}
              onClose={() => dispatch("setOpenDrawer")}
              ModalProps={{
                keepMounted: true,
              }}
              className={classes.drawer}
            >
              {drawer(navSelected, dispatch)}
            </Drawer>
          </Box>
        </Toolbar>
        {/* Mobile */}
        {isMobile && (
          <Toolbar>
            <div className={classes.searchApp}>
              <InputBase
                placeholder="Tìm kiếm"
                inputProps={{ "aria-label": "search" }}
                endAdornment={<img src={SearchIcon} alt="search" />}
              />
            </div>
            <Button
              variant="contained"
              endIcon={<img src={DownloadIcon} alt="download" />}
              className={classes.buttonApp}
              href="https://core.globalcare.vn/app/gsale/download"
              target="_blank"
            >
              TẢI GSALE NGAY!
            </Button>
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
}

import { makeStyles } from "@mui/styles";

const Title = (props) => {
  const { colorText, text, other } = props;
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <span className="color">{colorText}</span> {text}
      {other && <div>{other}</div>}
      <div className="center">
        <div className="divider"></div>
      </div>
    </div>
  );
};
export default Title;

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#1A1A1A",
    fontSize: "35px",
    fontWeight: 700,
    textAlign: "center",
    margin: "30px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      margin: "20px 0",
    },
    "& .center": { display: "flex", justifyContent: "center" },
    "& .color": { color: "#006838" },
    "& .divider": {
      backgroundColor: "#61646D",
      height: "3px",
      width: "100px",
      marginTop: "15px",
    },
  },
}));

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: "#4B4B4B",
    lineHeight: 1.5,
    margin: "30px 15%",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 10px 40px",
      fontSize: 15,
    },
    "& .title": {
      fontSize: 32,
      fontWeight: 700,
      textAlign: "center",
      margin: "30px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
  },
  contact: {
    margin: "40px 8%",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      margin: "20px 10px",
    },
    "& .title": {
      fontSize: 32,
      fontWeight: 700,
      margin: "20px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    "& .bold": {
      fontWeight: 600,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .color": {
      color: "#006838",
      fontWeight: 700,
      fontSize: 17,
    },
    "& .half": {
      margin: "10px 0",
      alignItems: "center",
      fontSize: 15,
      [theme.breakpoints.down("sm")]: {
        margin: "5px 0",
      },
    },
    "& .image": {
      width: "100%",
    },
    "& .mr-10": {
      marginRight: 10,
    },
    "& .mt-10": {
      marginTop: 10,
    },
    "& .fz-15": {
      fontSize: 15,
    },
    "& .wrapper ": {
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    "& .bg-color": {
      width: "50%",
      height: "460px",
      borderRadius: "0px 8.939px 8.939px 0px",
      background: "linear-gradient(211deg, #006838 0%, #00A69C 100%)",
    },
    "& .map ": {
      width: "calc(100% - 25px)",
      position: "absolute",
      right: 20,
      top: 20,
    },
  },
  accordion: {
    "& .color": {
      fontWeight: 600,
      color: "#006838",
    },
    "& .row-text": {
      display: "flex",
      flexDirection: "row",
    },
    "& .li": {
      marginLeft: 10,
      lineHeight: 1.6,
    },
    "& .add": {
      marginRight: 10,
    },
    "& .center": {
      textAlign: "center",
    },
    "& .underline": {
      fontWeight: 500,
      textDecoration: "underline",
    },
    "& .qr-code": {
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        width: "35%",
      },
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
  },
  closeIcon: {
    marginLeft: "auto",
  },
}));

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline",
    zIndex: 9,
    position: "sticky",
    "& .MuiAppBar-root": {
      position: "inherit",
      boxShadow: "0 4px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      background: "#EFF8F7",
      display: "flex",
      justifyContent: "center",
      padding: 0,
      [theme.breakpoints.up("lg")]: {
        height: "87px",
      },
    },
    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 30px",
      [theme.breakpoints.down("lg")]: {
        padding: "0 10px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .img": {
      [theme.breakpoints.down("lg")]: {
        width: "10%",
        paddingTop: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "22%",
        paddingTop: "10px",
      },
    },
  },
  header: {
    width: "100%",
    height: "87px",
    background: "#EFF8F7",
    padding: "0 32px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 16px",
    },
  },

  line: {
    background: "#61646D",
    width: "1px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 16px",
      height: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 40px",
      height: "47px",
    },
  },
  button: {
    background: "linear-gradient(272.23deg, #00805E 10.36%, #00A69C 100%)",
    height: 40,
    "&:hover": {
      boxShadow: "0px 2px 10px -2px #006838",
    },
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  buttonApp: {
    background: "linear-gradient(272.23deg, #00805E 10.36%, #00A69C 100%)",
    borderRadius: "8px !important",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    height: 40,
    width: "100%",
    padding: "5px !important",
  },
  search: {
    position: "relative",
    borderRadius: 8,
    backgroundColor: "#fff",
    color: "#A0A0A0",
    paddingRight: "16px",
    boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
    transition: "all .2s ease-in",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
      display: "none",
    },
    "& .MuiInputBase-input": {
      color: "#61646D",
      padding: "9px 8px 8px",
      transition: theme.transitions.create("width"),
      // width: "100%",
      [theme.breakpoints.down("lg")]: {
        "&:focus": {
          width: "50ch",
        },
      },
      [theme.breakpoints.up("lg")]: {
        width: "40ch",
      },
    },
  },
  searchApp: {
    position: "relative",
    borderRadius: 8,
    backgroundColor: "#fff",
    color: "#A0A0A0",
    marginRight: "10px",
    paddingRight: "8px",
    width: "100%",
    boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
    "& .MuiInputBase-root": {
      width: "96%",
    },
    transition: "all .2s ease-in",
    "& .MuiInputBase-input": {
      color: "#61646D",
      padding: "9px 8px 8px",
      transition: theme.transitions.create("width"),
    },
  },
  menu: {
    flexDirection: "row",
    flexGrow: 1,
    transition: "all .2s ease-in",
    justifyContent: "space-around",
    fontWeight: 500,
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
    "& .item": {
      cursor: "pointer",
      padding: "10px 16px",
      transition: "all 0.5s ease",
      "&:hover": {
        background: "#eaf7f5",
        borderRadius: 8,
        transform: "scale(1.05)",
      },
    },
    "& .active": {
      color: "#006838",
    },
    "& .inActive": {
      color: "#4B4B4B",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    display: { xs: "block", lg: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "240px",
    },
    "& .box": {
      textAlign: "center",
      background: "#EFF8F7",
      flexGrow: 1,
    },
    "& .item": {
      textAlign: "center",
      fontWeight: 500,
    },
    "& .active": {
      color: "#006838",
    },
    "& .inActive": {
      color: "#4B4B4B",
    },
  },
}));
